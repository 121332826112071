body
  .login-page
    width: 600px
    height: 400px
    .welcome-back
        color: #377dff
        font-size: 1.75rem
    .login-to-manage
        color: #77838f
        font-size: 1rem
        margin-bottom: 50px
    .login-form
      form
        .field
          margin-top: 10px
        input.login-button
          float: right
          width: 80px
          height: 50px
        div
          .input-field
            width: 570px
            height: 50px
